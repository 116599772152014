import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react'
import { callMsGraph } from '../utils/MsGraphApiCall'
import { loginRequest, msalInstance } from '../../authProvider'
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser'


type MsGraphContextProps = {
    children: React.ReactNode
}


type GraphDataProps = {
    displayName: string,
    mail: string,
    country: string
}

type MsGraphContext = {
    graphData: GraphDataProps,
    profilePicture?: string,
    country: string,
    sessionId?: string
}


export const MsGraphContext = React.createContext({} as MsGraphContext)

export default function MsGraphContextProvider (props: MsGraphContextProps) {

    const { accounts, inProgress } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [graphData, setGraphData] = React.useState<GraphDataProps>(null)
    const [profilePicture, setProfilePicture] = React.useState<string>()
    const [country, setCountry] = React.useState<string>()
    const [sessionId, setSessionId] = React.useState<string>('')
    // const sid = account.idTokenClaims.sid;
    const parentDomain = 'http://localhost:8001'

    React.useEffect(() => {
        const abortController = new AbortController()
        async function getAzureMsGraphData () {
          if (account && !graphData && inProgress === InteractionStatus.None) {
              const request = {
                ...loginRequest,
                account: account
                // account: account,
              };
              msalInstance.acquireTokenSilent(request).then((response) => {
                // setSessionId(sid)
                callMsGraph(response.accessToken).then((data) => {
                  const imageBlob = data[1] || null
                  const imageObjectUrl = URL.createObjectURL(imageBlob)
                  setGraphData(data[0])
                  setProfilePicture(imageObjectUrl)
                }).catch((error) => console.error(error))
              }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  msalInstance.acquireTokenRedirect(request)
                }
              })
              return () => abortController.abort()
            }
        }
        getAzureMsGraphData()
        if (graphData !== null) {
          setCountry(graphData.country)
        }
      }, [account, inProgress, graphData])


      return (
        <MsGraphContext.Provider value={{graphData, profilePicture, country, sessionId}}>
            {props.children}
        </MsGraphContext.Provider> 
      )

}

export const useMsGraph = () => React.useContext(MsGraphContext)