import React from 'react';
import html2canvas from 'html2canvas'
import { useSnackbar } from 'notistack';


type BookmarkContextProviderProps = {
    children: React.ReactNode
}

type BookmarkProps = {
    pageTitle: string,
    pagePath: string,
    lastEdit: string,
    pageHref: string,
    image: string,
    addedLast: string,
}

export type BookmarkContextType = {
    bookmarkValue: BookmarkProps[],
    addBookmarkData: (title?: any, commit?: any) => void,
    removeBookmarkData: (pageTitle?: string) => void,
}

export const BookmarkContext = React.createContext({} as BookmarkContextType)

export function BookmarkContextProvider(props: BookmarkContextProviderProps) {

    const key = 'semwiki-bookmarks'
    const data = typeof window !== 'undefined' ? localStorage.getItem(key) : null
    const [bookmarkValue, setBookmarkValue] = React.useState<BookmarkProps[]>([])
    const {enqueueSnackbar} = useSnackbar()
    let today = new Date().toLocaleDateString()


    const addBookmarkData = React.useCallback((title?: any, commit?: any) => {
        const bookmarkExists = bookmarkValue.some((item) => item.pagePath === location.pathname && item.pageTitle === title)
        if (bookmarkExists) {
            removeBookmarkData(title)
        } else {
            const node = document.getElementById('content')
            html2canvas(node, {height: 400, width: 500})
            .then((canvas) => {
                const data = canvas.toDataURL()
                const img = new Image()
                img.src = data
                setBookmarkValue([{
                    pageTitle: title,
                    pagePath: location.pathname,
                    pageHref: location.href,
                    lastEdit: commit,
                    image: data,
                    addedLast: today
                }, ...bookmarkValue ])
                // add a function for changing the snackbar
                enqueueSnackbar('Bookmark Added Successfully', {variant: 'success', anchorOrigin: {vertical: 'bottom', horizontal: 'right'}})
            })
            .catch((error) => {
                console.error(error)
            })
        }
    },[bookmarkValue])

    

    const removeBookmarkData = (pageTitle: string) => {
        setBookmarkValue(data => data.filter((item) => item.pageTitle !== pageTitle))
        enqueueSnackbar('Bookmark Removed', {variant: 'info', anchorOrigin: {vertical: 'bottom', horizontal: 'right'}})
        
    }
    
    React.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(bookmarkValue))
      },[bookmarkValue])

    React.useEffect(() => {
      if (data !== null) {
        setBookmarkValue(JSON.parse(data))
        
      }
    },[])


    return (
        <BookmarkContext.Provider value={{bookmarkValue, addBookmarkData, removeBookmarkData}}>
            {props.children}
        </BookmarkContext.Provider>
    )
}

export default React.memo(BookmarkContextProvider)
export const useBookmark = () => React.useContext(BookmarkContext)
