import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { LogsQueryResultStatus, LogsTable  } from "@azure/monitor-query"

const instrumentationKey = process.env.GATSBY_APPLICATION_INSIGHT

export const appInsightClient = new ApplicationInsights({config: {
    connectionString: instrumentationKey
}})

type TableType = {
    tables: {
        tables: LogsTable[]
    }
    status: LogsQueryResultStatus
}

export async function getPopularity() {
    const kustoQuery = "customEvents | extend query=tostring(tolower(customDimensions.Query)) | where isnotempty(query) | summarize Popularity=count() by query | order by Popularity | limit 5;"
    const url = `https://api.applicationinsights.io/v1/apps/${process.env.GATSBY_APP_INSIGHT_ID}/query?query=${kustoQuery}&timespan=P1D`

    const options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.GATSBY_APPLICATION_INSIGHT_API_KEY
        },
    }

    const result: TableType = await fetch(url, options)
    .then(async (response) => {
        if (response.status === 200) {
            const transformResult = {
                tables: await response.json(),
                status: LogsQueryResultStatus.Success
            }
            return transformResult
        }
    })

    if (result.status === LogsQueryResultStatus.Success) {
        const tableResult: LogsTable[] = result.tables.tables;
        let arr = [];
        for (const value of tableResult) {
            for (const row of value.rows) {
                const rowValue = row.map((value) => {
                    return value
                })
                const docsId = crypto.randomUUID()
                arr.push({
                    type: "Popular",
                    text: rowValue[0].toString(),
                    document: {
                        id: docsId
                    }
                })
            }
        }
        return arr;
    }
}