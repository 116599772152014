import { appInsightClient } from './src/utils/AppInsightApiCall';
import wrapWithProvider from './src/wrap-with-provider';


export const wrapRootElement = wrapWithProvider


export const onInitialClientRender = () => {
        appInsightClient.loadAppInsights();
        window.appInsightClient = appInsightClient
    }

export const onRouteUpdate = ({location, prevLocation}) => {
    if (window.appInsightClient) window.appInsightClient.trackPageView()
}

