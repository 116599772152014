import { PublicClientApplication } from '@azure/msal-browser'

const redirectUri = process.env.GATSBY_AZURE_REDIRECT_URI
const clientId = process.env.GATSBY_AZURE_CLIENT_ID;
const tenantId = process.env.GATSBY_AZURE_TENANT_ID;

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        allowRedirectInIframe: true
    }
}

export const loginRequest = {
    scopes: ["User.Read"]
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/beta/me",
    graphProfilePicture: "https://graph.microsoft.com/v1.0/me/photo/$value",
}

export const msalInstance = new PublicClientApplication(msalConfig)