exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-intro-to-sem-md": () => import("./../../../src/pages/articles/intro-to-sem.md" /* webpackChunkName: "component---src-pages-articles-intro-to-sem-md" */),
  "component---src-pages-articles-sem-accomplishments-md": () => import("./../../../src/pages/articles/sem-accomplishments.md" /* webpackChunkName: "component---src-pages-articles-sem-accomplishments-md" */),
  "component---src-pages-articles-tsa-md": () => import("./../../../src/pages/articles/tsa.md" /* webpackChunkName: "component---src-pages-articles-tsa-md" */),
  "component---src-pages-contribute-index-md": () => import("./../../../src/pages/contribute/index.md" /* webpackChunkName: "component---src-pages-contribute-index-md" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-what-is-sem-components-tsx": () => import("./../../../src/pages/what-is-sem/components.tsx" /* webpackChunkName: "component---src-pages-what-is-sem-components-tsx" */),
  "component---src-templates-pages-tsx": () => import("./../../../src/templates/Pages.tsx" /* webpackChunkName: "component---src-templates-pages-tsx" */)
}

