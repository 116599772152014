import { graphConfig } from '../../authProvider'

export async function callMsGraph(accessToken) {
    const header = new Headers()
    const bearer = `Bearer ${accessToken}`
    header.append("Authorization", bearer)
    const options = {
        method: "GET",
        headers: header
    }
    return await Promise.all([
        fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.error(error)),
        fetch(graphConfig.graphProfilePicture, options)
        .then(response => response.blob())
        .catch(error => console.error(error))
    ])
}