import React from 'react'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'


type DrawerStateProps = {
    children: React.ReactNode
}

type DrawerStateContextProps = {
    drawerState: boolean,
    handleDrawerState: () => void,
    matches: boolean,
    open: boolean,
    toggleCollapse: () => void
}


export const DrawerStateContext = React.createContext({} as DrawerStateContextProps)

export default function DrawerStateContextProvider (props: DrawerStateProps) {

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const [drawerState, setDrawerState] = React.useState<boolean>(false)
    const [open, setOpen] = React.useState<boolean>()

    const toggleCollapse = () => {
        setOpen(!open)
    }
    

    React.useEffect(() => {
        if (matches) {
            setDrawerState(false)
        } else {
            setDrawerState(true)
        }
    },[matches])
    
    const handleDrawerState = () => {
        setDrawerState(!drawerState)
    }

    return (
       <DrawerStateContext.Provider value={{drawerState, handleDrawerState, matches, toggleCollapse, open}}> 
            {props.children}
       </DrawerStateContext.Provider>
    )


}

export const useDrawerState = () => React.useContext(DrawerStateContext)