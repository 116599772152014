import React from 'react';
import { AutoCompleteProps } from '../components/SearchBar';
import { v4 as uuidv4 } from 'uuid';




type BookmarkContextProviderProps = {
    children?: React.ReactNode
}


type SearchContentType = {
    searchQuery?: string
    setSearchQuery?: React.Dispatch<React.SetStateAction<string>>,
    addRecentSearch?: (value: string) => void,
    recentSearch?: AutoCompleteProps[],
    popularSearch?: AutoCompleteProps[],
    setPopularSearch?: React.Dispatch<React.SetStateAction<AutoCompleteProps[]>>,

}

export const SearchContext = React.createContext({} as SearchContentType)



export default function SearchContextProvider (props: BookmarkContextProviderProps) {

    const [searchQuery , setSearchQuery] = React.useState<string>(null)
    const [recentSearch, setRecentSearch] = React.useState<AutoCompleteProps[]>([])
    const [popularSearch, setPopularSearch ] = React.useState<AutoCompleteProps[]>([])
    const recentSearchKey = "recent-search"
    const data = typeof window !== 'undefined' ? localStorage.getItem(recentSearchKey) : null
    
    React.useEffect(() => {
        if (data !== null) {
            setRecentSearch(JSON.parse(data))
        }
    },[])

    React.useEffect(() => {
        localStorage.setItem(recentSearchKey, JSON.stringify(recentSearch))
    },[recentSearch])
    


    const addRecentSearch = (recentSearchValue: string) => {
        const checkifRecentExist = recentSearch.some(value => value.text === recentSearchValue)
        const docsId = crypto.randomUUID()
       if (!checkifRecentExist) {
            setRecentSearch([{
                type: "Recent Searches",
                text: recentSearchValue,
                document: {
                    id: docsId
                }
            }, ...recentSearch])
       } else {
            const newValue = recentSearch.filter(value => value.text !== recentSearchValue)
            setRecentSearch([{
                type: "Recent Searches",
                text: recentSearchValue,
                document: {
                    id: docsId ? docsId : `${uuidv4()}`
                }
            }, ...newValue])
       }
       console.log()
    }

    return (
        <SearchContext.Provider value={{searchQuery, setSearchQuery, addRecentSearch, recentSearch, popularSearch, setPopularSearch}}>
            {props.children}
        </SearchContext.Provider>
    )



}

export const useSearch = () => React.useContext(SearchContext)