import React from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react'
import { msalConfig } from '../authProvider'
import BookmarkContextProvider from './context/BookmarkContext';
import SearchContextProvider from './context/SearchContext';
import MsGraphContextProvider from './context/MsGraphContext';
import DrawerStateContextProvider from './context/DrawerStateContext';
import { SnackbarProvider } from 'notistack';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authProvider';


const msalInstance = new PublicClientApplication(msalConfig)

const WrapProvider = ({ element }) => {
    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
            >
                <SnackbarProvider>
                    <BookmarkContextProvider>
                        <SearchContextProvider>
                            <MsGraphContextProvider>
                                <DrawerStateContextProvider>
                                    {element}
                                </DrawerStateContextProvider>
                            </MsGraphContextProvider>
                        </SearchContextProvider>
                    </BookmarkContextProvider>
                </SnackbarProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    )
}

export default WrapProvider;